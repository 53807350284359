import React, {useEffect, useState} from 'react';
import MainMenu from "../../components/Main/MainMenu";
import Stack from "@mui/material/Stack";
import Header from "../../components/Main/Header";
import ReuseComponentBuilder from "../../components/ReuseComponent";
import {Box, CircularProgress, List, ListItem, ListItemButton, ListItemText, Typography} from "@mui/material";
import {SuggestionResponse} from "../../types/api/SuggestionResponse";
import $api from "../../api/api";
import {AppServices} from "../../api/services/AppServices";
import {useNavigate, useParams} from "react-router-dom";
import AddSuggestion from "../../components/Main/buttons/AddSuggestion";
import IconButton from "@mui/material/IconButton";
import { Delete} from "@mui/icons-material";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {useSuggestionStorage} from "../../storage/SuggestionStore";
import Button from "@mui/material/Button";


const SuggestionsListPage = () => {

    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null | unknown>(null);
    const {listId}=useParams()
    const {setSuggestions,suggestions,removeSuggestion}=useSuggestionStorage()
    useEffect(()=>{
        fetchSuggestions().then()
    },[])

    async function fetchSuggestions(){
        setLoading(true)
        try{
            const res=await AppServices.fetchSuggestion(listId)
            setSuggestions(res)
        }catch (e){
            setError(e)
        }finally {
            setLoading(false)
        }
    }
    function deleteSuggestions(item:SuggestionResponse){
        confirmAlert({
            title: 'Удалить?',
            message: `Удалить подсказку ${item.name}?`,
            buttons: [
                {
                    label: 'Да',
                    onClick: () => destroySuggestion(item)
                },
                {
                    label: 'Нет',
                }
            ]
        });
    }
    async function destroySuggestion(item:SuggestionResponse) {
        try {
            const res=await AppServices.deleteSuggestion(item.id)
            removeSuggestion(item.id)
        }catch (e){
            alert(e)
        }
    }

    return (
        <MainMenu>
            <Stack
                spacing={2}
                sx={{
                    mx: 3,
                    pb: 10,
                    mt: {xs: 8, md: 0},
                }}
            >
                <Header>
                    <AddSuggestion listId={listId!}/>
                </Header>
                <ReuseComponentBuilder<SuggestionResponse[]>
                    loading={loading}
                    error={error}
                    data={suggestions}>
                    {(data) => (
                        <>
                            {
                                data.length==0 && <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    height="80vh"
                                    flexDirection="column"
                                >
                                    <Typography variant="h6" gutterBottom>
                                        Список пуст. Создайте первую подсказку.
                                    </Typography>
                                    <AddSuggestion listId={listId!}/>
                                </Box>
                            }
                            {
                                data.length>0&&<List disablePadding>
                                    {
                                        data.map((item, index) => (
                                            <ListItem
                                                key={`list-${item.id}-${index}`}
                                                secondaryAction={
                                                    <IconButton
                                                        onClick={()=>deleteSuggestions(item)}
                                                    >
                                                        <Delete/>
                                                    </IconButton>
                                                }
                                            >
                                                <ListItemText primary={
                                                    <Typography variant="h6" component="span">
                                                        {item.name}
                                                    </Typography>
                                                } secondary={
                                                    <Typography
                                                        component={'span'}
                                                        variant={'subtitle1'}
                                                        sx={{
                                                            display: '-webkit-box',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            WebkitBoxOrient: 'vertical',
                                                            WebkitLineClamp: 1,
                                                            width: '200px'
                                                        }}>
                                                        <Typography
                                                            noWrap
                                                            component="span"
                                                            variant="body2"
                                                            color="textSecondary"
                                                            sx={{display: 'inline'}}
                                                        >
                                                            Сообщение:
                                                        </Typography>
                                                        {` ${item.message}`}
                                                    </Typography>
                                                }/>
                                            </ListItem>
                                        ))
                                    }
                                </List>
                            }

                        </>
                    )}
                </ReuseComponentBuilder>
            </Stack>
        </MainMenu>
    );
};

export default SuggestionsListPage;