import React from 'react';
import {useSearchParams} from "react-router-dom";
import {Box, Typography} from "@mui/material";

const NeedPayPage = () => {
    const [searchParams] = useSearchParams();

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            width={500}
        >
            <Typography>Упс...</Typography>
            <Typography>похоже подписка закончилась. {searchParams.get('subscribeDone')}</Typography>
        </Box>

    );
};

export default NeedPayPage;