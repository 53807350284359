import React from 'react';
import MainMenu from "../../components/Main/MainMenu";
import Stack from "@mui/material/Stack";
import Header from "../../components/Main/Header";
import OrdersComponent from "../../components/Main/OrdersComponent";
import {useAppStorage} from "../../storage/AppStorage";
import {Typography} from "@mui/material";
import {format} from "date-fns";
import {ru} from "date-fns/locale";
import {usePortalDataStorage} from "../../storage/PortalDataStorage";

const OrderPage = () => {
    const {deadlineSubscribe}=usePortalDataStorage()
    const {portalId}=usePortalDataStorage()
    return (
        <MainMenu>
            <Stack
                spacing={2}
                sx={{
                    mx: 3,
                    pb: 10,
                    mt: {xs: 8, md: 0},
                }}
            >
                <Header>
                    <Typography>Ваша подписка доступна до {format(deadlineSubscribe!, 'dd MMMM yyyy', { locale: ru })}</Typography>
                </Header>
                <OrdersComponent portalId={portalId??''}/>
            </Stack>
        </MainMenu>
    );
};

export default OrderPage;