// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: #f5f5f5;
}

.title-section {
    display: flex;
    align-items: center;
}

.title {
    font-size: 18px;
    margin-right: 5px;
}

.star-icon {
    color: #999;
}

.search-section {
    flex-grow: 1;
    margin: 0 20px;
}

.search-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.action-buttons {
    display: flex;
    align-items: center;
}

.basket-button,
.settings-button,
.add-button {
    background-color: #007bff;
    color: white;
    padding: 8px 12px;
    margin-left: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.basket-button {
    background-color: #ccc;
}

.settings-button {
    background-color: #ddd;
    color: #333;
}

.add-button {
    background-color: #00aaff;
}

.dropdown-icon {
    margin-left: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/MainPage/FileHeader.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;;;IAGI,yBAAyB;IACzB,YAAY;IACZ,iBAAiB;IACjB,iBAAiB;IACjB,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".file-header {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: space-between;\r\n    padding: 10px;\r\n    background-color: #f5f5f5;\r\n}\r\n\r\n.title-section {\r\n    display: flex;\r\n    align-items: center;\r\n}\r\n\r\n.title {\r\n    font-size: 18px;\r\n    margin-right: 5px;\r\n}\r\n\r\n.star-icon {\r\n    color: #999;\r\n}\r\n\r\n.search-section {\r\n    flex-grow: 1;\r\n    margin: 0 20px;\r\n}\r\n\r\n.search-input {\r\n    width: 100%;\r\n    padding: 8px;\r\n    border: 1px solid #ccc;\r\n    border-radius: 4px;\r\n}\r\n\r\n.action-buttons {\r\n    display: flex;\r\n    align-items: center;\r\n}\r\n\r\n.basket-button,\r\n.settings-button,\r\n.add-button {\r\n    background-color: #007bff;\r\n    color: white;\r\n    padding: 8px 12px;\r\n    margin-left: 10px;\r\n    border: none;\r\n    border-radius: 4px;\r\n    cursor: pointer;\r\n}\r\n\r\n.basket-button {\r\n    background-color: #ccc;\r\n}\r\n\r\n.settings-button {\r\n    background-color: #ddd;\r\n    color: #333;\r\n}\r\n\r\n.add-button {\r\n    background-color: #00aaff;\r\n}\r\n\r\n.dropdown-icon {\r\n    margin-left: 5px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
