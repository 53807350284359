import $api from "../api";
import {SuggestionListResponse} from "../../types/api/SuggestionListResponse";
import {TeamResponse} from "../../types/api/TeamResponse";

export class TeamService{
    static async fetchTeams(portalId:string):Promise<TeamResponse[]>{
        try {
            const res=await $api.get<TeamResponse[]>(
                '/portal/teams',
                {
                    params:{
                        time:new Date().getTime(),
                        portalId:portalId
                    }
                }
            );
            return res.data;
        }catch (e) {
            throw new Error(`Error fetching data: ${e}`);
        }
    }
}