import React, {FC, useEffect, useState} from 'react';
import {Box, CircularProgress, List, ListItem, ListItemButton, ListItemText, Typography} from "@mui/material";
import AddList from "./buttons/AddList";
import Header from "./Header";
import Stack from "@mui/material/Stack";
import {useNavigate} from "react-router-dom";
import {useListStorage} from "../../storage/ListStorage";
import EditListButton from "./buttons/EditListButton";


const ListPage: FC = () => {
    const navigate = useNavigate();
    const {lists}=useListStorage()
    return (
        <>
            <Stack
                spacing={2}
                sx={{
                    mx: 3,
                    pb: 10,
                    mt: { xs: 8, md: 0 },
                }}
            >
                <Header>
                    <AddList/>
                </Header>
                <List
                >
                    {
                        lists.map((item, index) => (
                            <ListItem key={`list-${item.id}-${index}`} secondaryAction={
                                <Stack direction={'row'} gap={2}>
                                    <EditListButton name={item.name}  listId={item.id} teamIds={item.teams.map((t)=>t.id)}/>
                                    <Typography>{item.suggestionsCount}</Typography>
                                </Stack>
                            }>
                                <ListItemButton
                                    onClick={()=>navigate(`/list/${item.id}`)}
                                >
                                    <ListItemText primary={item.name} secondary={
                                        <React.Fragment>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                sx={{color: 'text.primary', display: 'inline'}}
                                            >
                                                Для команд:
                                            </Typography>
                                            {` ${
                                                item.teams.map((team)=>team.name).join(', ')
                                            }`}
                                        </React.Fragment>
                                    }/>
                                </ListItemButton>
                            </ListItem>
                        ))
                    }

                </List>
            </Stack>


        </>
    );
};

export default ListPage;