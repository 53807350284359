import React from 'react';
import {BxButton} from "react-bitrix24";
import {useNavigate} from "react-router-dom";
import ym from "react-yandex-metrika";

const AppBar = () => {
    const navigate = useNavigate();

    return (
        <div className="file-header">
            <div className="title-section">
                <span className="title">ChatBoost</span>
            </div>
            <div className="action-buttons">
                <BxButton type={'button'} color={'secondary'}>Подписка</BxButton>
                <BxButton type={'button'} color={'primary'} onClick={() => {
                    ym('reachGoal','add_list')
                    navigate('/add-list')
                }}>
                    Создать список
                </BxButton>
            </div>
        </div>
    );
};

export default AppBar;