import React, {useEffect, useState} from 'react';
import './FileHeader.css'
import {useParams, useSearchParams} from "react-router-dom";
import {
    AppBar,
    Box,
    CssBaseline,
    Divider,
    Drawer,
    List,
    ListItem, ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography
} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import ListPage from "../../components/Main/ListPage";
import {ContentCopy, CopyAll} from "@mui/icons-material";
import OrdersComponent from "../../components/Main/OrdersComponent";
import TeamsComponent from "../../components/Main/TeamsComponent";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import MainMenu from "../../components/Main/MainMenu";
import {useAppStorage} from "../../storage/AppStorage";


const MainPage = () => {
    const {portalId} = useParams();
    const [searchParams] = useSearchParams();
    const {setToken,setPortalId}=useAppStorage()


    useEffect(() => {
        if (searchParams.has('token')) {
            localStorage.setItem('authToken', searchParams.get('token')!)
            localStorage.setItem('portalId', portalId??'')
            setToken(searchParams.get('token')??'')
            setPortalId(portalId??'')
        } else {
            alert('токен не получен')
        }
    }, []);



    return (
        <>
            <MainMenu>
                <ListPage/>
            </MainMenu>
        </>

    );
};

export default MainPage;