import {TeamResponse} from "../types/api/TeamResponse";
import {create} from "zustand";
import {persist} from "zustand/middleware";

interface TeamStoreAttrs{
    teams:TeamResponse[]
    setTeams:(value:TeamResponse[]) => void
    unsetTeam:(id:string,value:Partial<TeamResponse>) => void
    removeTeam:(id:string) => void
    addTeam:(value:TeamResponse) => void

}
export const useTeamsStorage = create(
    persist<TeamStoreAttrs>(
        (set,get)=>({
            teams:[],
            setTeams:(value:TeamResponse[]) => set({teams:value}),
            unsetTeam: (id: string, updatedTeam: Partial<TeamResponse>) => set((state) => ({
                teams: state.teams.map((user) =>
                    user.id === id ? { ...user, ...updatedTeam } : user
                )
            })),
            removeTeam: (id: string) => set((state) => ({
                teams: state.teams.filter((user) => user.id !== id)
            })),
            addTeam: (team: TeamResponse) => set((state) => ({teams:[team,...state.teams]}))
        }),
        {
            name: 'teams-storage',
        }
    ),

)