import React, {useEffect, useState} from 'react';
import Stack from "@mui/material/Stack";
import Header from "../../components/Main/Header";
import MainMenu from "../../components/Main/MainMenu";
import {Alert, AlertTitle, List, ListItem, ListItemText, SelectChangeEvent} from "@mui/material";
import ReuseComponentBuilder from "../../components/ReuseComponent";
import {UserResponse} from "../../types/api/UserResponse";
import {AppServices, fetchTeams} from "../../api/services/AppServices";
import EditUserButton from "../../components/Main/buttons/EditUserButton";
import Chip from "@mui/material/Chip";

const UsersPage = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null|unknown>(null);
    const [teams,setTeams] = useState<UserResponse[]>([]);


    useEffect(() => {
        initData().then()
    }, []);

    async function initData(){
        setLoading(true)
        try{
            const res=await AppServices.fetchUsers()
            setTeams(res)
        }catch (e){
            setError(e)
        }
        finally {
            setLoading(false)
        }
    }


    return (
        <MainMenu>
            <Stack
                spacing={2}
                sx={{
                    mx: 3,
                    pb: 10,
                    mt: { xs: 8, md: 0 },
                }}
            >
                <Header>
                </Header>
                <ReuseComponentBuilder<UserResponse[]>
                    loading={loading}
                    error={error}
                    data={teams}
                >
                    {(data) => (
                        <>
                            {
                                (data.some((item, index) => !item.readyToGo))&&<Alert severity="warning">
                                    <AlertTitle>Внимание!</AlertTitle>
                                    Один или несколько сотрудников не запустили приложение. Для работы им необходимо открыть приложение и дождаться загрузки данных.
                                </Alert>
                            }
                            <List>
                                {
                                    data.map((item, index) => (
                                        <ListItem key={`team-${item.id}-${index}`}
                                                  secondaryAction={
                                                      <Stack direction="row" gap={2}>
                                                          <EditUserButton team={item.team} userId={item.id}/>
                                                          {
                                                              item.readyToGo?
                                                                  <Chip label="Работает" color="success" />
                                                                  :
                                                                  <Chip label="Нужна авторизация" color="error" />
                                                          }

                                                      </Stack>
                                                  }
                                        >
                                            <ListItemText
                                                primary={`${item.lastName} ${item.name}`}
                                                secondary={`${item.team.name}`}
                                            >
                                            </ListItemText>
                                        </ListItem>
                                    ))
                                }
                            </List>
                        </>
                    )}
                </ReuseComponentBuilder>
            </Stack>
        </MainMenu>
    );
};

export default UsersPage;