import React from 'react';
import {SvgIcon, Typography} from "@mui/material";

const LogoApp = () => {
    return (
        <>
            <SvgIcon sx={{height: 52, width: 52,}}>
                <svg width="1024" height="1024" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"
                >
                    <path id="--" fill="#ffffff" fill-rule="evenodd" stroke="none"
                          d="M 221 920 L 277.398438 797.847656 C 189.451721 731.144836 133 627.787109 133 511.757813 C 133 310.593018 302.684296 147.517578 512 147.517578 C 721.315735 147.517578 891 310.593018 891 511.757813 C 891 712.922607 721.315735 876 512 876 C 441.696899 876 375.863525 857.604248 319.429688 825.546875 L 221 920 Z"/>
                    <path id="path1" fill="#0376bb" fill-rule="evenodd" stroke="none"
                          d="M 449.305939 666.667236 L 449.305939 666.667236 C 409.186707 706.786499 404.700653 734.212402 411.072845 740.584595 C 417.445038 746.956787 457.615295 732.275269 497.734528 692.156006 C 537.85376 652.036743 559.873596 598.813782 539.790955 578.73114 L 558.907471 559.614624 C 649.704712 468.817383 691.54834 363.450745 676.15564 348.058105 C 660.763 332.665466 555.396423 374.509033 464.599182 465.306274 L 445.482635 484.422852 C 425.399994 464.34021 372.177002 486.359985 332.05777 526.479248 C 291.938538 566.598511 277.256989 606.768738 283.629181 613.14093 C 290.001373 619.513123 317.427246 615.0271 357.546509 574.907837 L 449.305939 666.667236 Z M 539.790955 484.422852 C 532.048462 476.680359 519.495728 476.680481 511.753326 484.422852 C 504.010956 492.165222 504.010895 504.718018 511.753326 512.460449 C 519.495789 520.202881 532.048584 520.20282 539.790955 512.460449 C 547.533325 504.718079 547.533386 492.165283 539.790955 484.422852 Z M 497.734528 526.479248 C 487.880676 516.625366 471.904236 516.625305 462.050293 526.479248 C 452.196381 536.333191 452.196442 552.30957 462.050293 562.163452 C 471.904175 572.017334 487.880585 572.017395 497.734528 562.163452 C 507.58847 552.30957 507.588379 536.33313 497.734528 526.479248 Z M 430.189392 657.746216 C 430.189392 657.746216 416.309601 667 407.249542 673.039429 C 402.884674 676.066101 396.978271 675.529053 393.230713 671.765015 L 352.448761 630.983032 C 348.684723 627.235474 348.147675 621.329102 351.174316 616.964233 C 357.213806 607.904175 366.46756 594.024414 366.46756 594.024414 L 430.189392 657.746216 Z"/>
                </svg>
            </SvgIcon>
            <Typography color={'primary'}>ChatBoost</Typography>
        </>
    );
};

export default LogoApp;