import React from 'react';
import ListPage from "../../components/Main/ListPage";
import MainMenu from "../../components/Main/MainMenu";
import {useAppStorage} from "../../storage/AppStorage";

const AppPage = () => {
    return (
        <MainMenu>
            <ListPage/>
        </MainMenu>
    );
};

export default AppPage;