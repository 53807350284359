import React from 'react';
import AppBar from "../components/AppBar";
import {BxButton, BxInput} from "react-bitrix24";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import $api from "../api/api";
import {Box, Typography} from "@mui/material";

type Inputs = {
    name: string
}
const AddListPage = () => {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        control
    } = useForm<Inputs>()
    const onSubmit: SubmitHandler<Inputs> =async (data) => {
        try{
            const res=await $api.post(
                '/suggestions/lists',
                data
            )
            alert(res.data)
        } catch (e){
            alert(e)

        }
    }



    return (
        <div>
            <AppBar/>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh"
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Typography>
                        Название листа
                    </Typography>
                    <Controller
                        render={({field: {value, onChange, onBlur}}) => (
                            <BxInput placeholder={'Название списка'} value={value} size={'xs'}
                                     onChange={e => onChange(e.target.value)}
                            />
                        )}
                        name={'name'}
                        control={control}
                    />

                    <BxButton type={'submit'}>Создать</BxButton>
                </form>
            </Box>

        </div>
    );
};

export default AddListPage;