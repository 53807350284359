import React, {FC, useState} from 'react';
import {Edit} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select} from "@mui/material";
import {useAppStorage} from "../../../storage/AppStorage";
import {TeamResponse} from "../../../types/api/TeamResponse";
import {AppServices} from "../../../api/services/AppServices";
import {useNavigate} from "react-router-dom";
import {AxiosError} from "axios";
import {useTeamsStorage} from "../../../storage/TeamStore";

interface EditUserButtonAttrs{
    team:TeamResponse
    userId:string
}
const EditUserButton:FC<EditUserButtonAttrs> = ({team,userId}) => {
    const [open,setOpen]=useState(false);
    const [select,setSelect]=useState(team.id)
    const {teams}=useTeamsStorage()
    const navigate = useNavigate();


    async function updateUser() {
        try{
            const res=await AppServices.updateUsers(userId,select)
            setOpen(false)
            navigate(0)
        }catch (e){
            if(e instanceof AxiosError){
                alert(`Ошибка запроса ${e.status} ${e.response?.data.message}`)
            }else{
                alert(e)
            }
        }

    }


    return (
        <>
            <IconButton onClick={() => setOpen(true)}>
                <Edit/>
            </IconButton>


            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Изменить команду пользователя</DialogTitle>
                <DialogContent>
                    <Select
                        value={select}
                        onChange={(e)=>setSelect(e.target.value)}
                        defaultValue={team.id}
                        fullWidth
                    >
                        {
                            teams.map((team,index) => (
                                <MenuItem value={team.id} key={index}>{team.name}</MenuItem>
                            ))
                        }
                    </Select>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>setOpen(false)}>Закрыть</Button>
                    <Button onClick={updateUser}>Сохранить</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default EditUserButton;