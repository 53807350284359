import Grid from '@mui/material/Grid2';
import React, {useEffect, useState} from 'react';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import $api from "../../api/api";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import Stack from "@mui/material/Stack";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import {ArrowBackIos} from "@mui/icons-material";
import {Alert, Box, LinearProgress, Typography} from "@mui/material";
import {SuggestionResponse} from "../../types/api/SuggestionResponse";
import Toolbar from "@mui/material/Toolbar";
import {ButtonWithState, useButtonController} from "../../components/ButtonWithState";
import handleError from "../../utils/HandleError";

type Inputs = {
    message: string,
    suggestionId:string
}
const SendPage = () => {
    const { id } = useParams();
    const [data,setData]=useState<SuggestionResponse|null>()
    const [error,setError]=useState<string|undefined>();
    const [loading,setLoading]=useState<boolean>(true);
    const [searchParams] = useSearchParams();
    const buttonController = useButtonController();

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        control,
        setValue
    } = useForm<Inputs>({
        defaultValues:{
            suggestionId:id
        }
    })
    const navigate = useNavigate();

    useEffect(()=>{
        downloadData().then()
    },[])

    async function downloadData(){
        try{
            const res=await $api.get<SuggestionResponse>(
                `/suggestions/view/${id}`,
            )
            setData(res.data)
            setValue('message',res.data.message)
        }catch (e){
            setError(handleError(e))
        }finally {
            setLoading(false);
        }

    }

    const onSubmit: SubmitHandler<Inputs> =async (data) => {
        buttonController.startLoading()
        try{
            const res=await $api.post(
                '/suggestions/send',
                {
                    ...data,
                    chatId:searchParams.get('dialog')
                }
            )
            await buttonController.showSuccess();
        } catch (e){
            setError(handleError(e))
            await buttonController.showError();
        }
    }
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" sx={{ bgcolor: "white" }} elevation={0}>
                <Toolbar >
                    <IconButton
                        size="large"
                        edge="start"
                        aria-label="menu"
                        sx={{ mr: 2,color:'black'}}
                        onClick={()=>navigate(-1)}
                    >
                        <ArrowBackIos />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{color:'black',flexGrow: 1}}  >
                        {
                            (!data)?'Подсказка':data.name
                        }
                    </Typography>
                </Toolbar>
            </AppBar>
            {
                loading && <LinearProgress />
            }
            {
                error && <Alert severity="error">{error}</Alert>
            }
            {
                (!loading) && <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2} m={1}>
                        <Grid size={12}>
                            <Stack>
                                <Controller
                                    render={({field: {value, onChange, onBlur}, fieldState, formState,}) => (
                                        <TextField
                                            multiline
                                            rows={6}
                                            value={value}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                        />
                                    )}
                                    name={'message'}
                                    control={control}
                                />
                                <ButtonWithState
                                    controller={buttonController}
                                    sx={{mt: 2}}
                                    type={"submit"}
                                    variant="contained">
                                    Отправить
                                </ButtonWithState>
                            </Stack>
                        </Grid>
                    </Grid>
                </form>
            }

        </Box>

    );
};

export default SendPage;