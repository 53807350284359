import React, {useState} from 'react';
import Button from "@mui/material/Button";
import {Alert, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText} from "@mui/material";
import TextField from "@mui/material/TextField";
import {ButtonWithState, useButtonController} from "../../ButtonWithState";
import {SubmitHandler, useForm} from "react-hook-form";
import $api from "../../../api/api";
import handleError from "../../../utils/HandleError";

type Inputs = {
    domain: string
    email:string
}

const BuyOnSiteButton = () => {
    const [open,setOpen]=useState(false)
    const buttonController = useButtonController();
    const [error,setError]=useState<string|undefined>()


    const handleClose = () =>{
        setOpen(false)
    }

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        control
    } = useForm<Inputs>({

    })
    const onSubmit: SubmitHandler<Inputs> =async (data) => {
        buttonController.startLoading()
        try{
            const res=await $api.post(
                '/orders/buy-site',
                {
                    email:data.email,
                    domain:data.domain.replaceAll('https://','').replaceAll('/','')
                }
            )
            await buttonController.showSuccess()
            handleClose()
            window.location.href = res.data.paymentUrl
        } catch (e){
            setError(handleError(e))
            await buttonController.showError()

        }
    }


    return (
        <>
            <Button
                fullWidth
                variant={ 'contained'}
                color={'primary'}
                onClick={()=>setOpen(true)}
            >
                Купить доступ на 30 дней за 499 ₽
            </Button>

            <Dialog open={open}
                    onClose={handleClose}
                    PaperProps={{
                        component: 'form',
                        onSubmit: handleSubmit(onSubmit),
                    }}
            >
                <DialogTitle>Оформить заказ</DialogTitle>
                <DialogContent>
                    {
                        error!=undefined&&<Alert severity="success">{error}</Alert>

                    }
                    <FormControl required fullWidth variant="standard" error={errors.domain!=undefined}>
                        <TextField
                            label="Введите домен Битрикс24"
                            fullWidth
                            error={errors.domain!=undefined}
                            margin="dense"
                            {...register('domain',{required:{value:true,message:'Заполните поле'}})}
                        />

                        {
                            (errors.domain!=undefined)&&<FormHelperText >
                                {errors.domain.message}
                            </FormHelperText>
                        }
                    </FormControl>
                    <FormControl required fullWidth variant="standard" error={errors.email!=undefined}>
                        <TextField
                            label="Email для чека"
                            fullWidth
                            error={errors.email!=undefined}
                            margin="dense"
                            {...register('email',{required:{value:true,message:'Заполните поле'}})}
                        />
                        {
                            (errors.email!=undefined)&&<FormHelperText >
                                {errors.email.message}
                            </FormHelperText>
                        }
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Отмена</Button>
                    <ButtonWithState type={'submit'} controller={buttonController}>Купить</ButtonWithState>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default BuyOnSiteButton;