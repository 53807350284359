import React from 'react';
import MainMenu from "../../components/Main/MainMenu";
import TeamsComponent from "../../components/Main/TeamsComponent";
import Stack from "@mui/material/Stack";
import Header from "../../components/Main/Header";
import AddTeam from "../../components/Main/buttons/AddTeam";

const TeamsPage = () => {

    return (
        <MainMenu>
            <Stack
                spacing={2}
                sx={{
                    mx: 3,
                    pb: 10,
                    mt: { xs: 8, md: 0 },
                }}
            >
                <Header>
                    <AddTeam/>
                </Header>
                <TeamsComponent portalId={localStorage.getItem('portalId')??''}/>
            </Stack>
        </MainMenu>


    );
};

export default TeamsPage;