import {SuggestionListResponse} from "../types/api/SuggestionListResponse";
import {create} from "zustand";
import {persist} from "zustand/middleware";

interface ListStorageAttrs {
    lists:SuggestionListResponse[]
    setLists:(value:SuggestionListResponse[]) => void
    unsetList:(id:string,value:Partial<SuggestionListResponse>) => void
    removeList:(id:string) => void
    addList:(value:SuggestionListResponse)=>void
}

export const useListStorage = create(
    persist<ListStorageAttrs>(
        (set,get)=>({
            lists:[],
            setLists:(value:SuggestionListResponse[]) => set({lists:value}),
            unsetList: (id: string, updatedUser: Partial<SuggestionListResponse>) => set((state) => ({
                lists: state.lists.map((user) =>
                    user.id === id ? { ...user, ...updatedUser } : user
                )
            })),
            removeList: (id: string) => set((state) => ({
                lists: state.lists.filter((user) => user.id !== id)
            })),
            addList:(value:SuggestionListResponse)=>set((state)=>({
                lists:[value,...state.lists]
            }))
        }),
        {
            name: 'lists-storage',
        }
    ),

)