import $api from "../api";
import {SuggestionListResponse} from "../../types/api/SuggestionListResponse";
import {ChatSuggestionResponse} from "../../types/api/ChatSuggestionResponse";
import {TeamResponse} from "../../types/api/TeamResponse";
import {SuggestionResponse} from "../../types/api/SuggestionResponse";
import {AxiosError} from "axios";
import {UserResponse} from "../../types/api/UserResponse";

export class AppServices {
    static async fetchListsSuggestion(portalId?:string):Promise<SuggestionListResponse[]>{
        try {
            const res=await $api.get<SuggestionListResponse[]>(
                '/suggestions/lists',
                {
                    params:{
                        time:new Date().getTime(),
                        portalId:portalId
                    }
                }
            );
            return res.data;
        }catch (e) {
            throw e
        }
    }
    static async fetchSuggestion(list?:string):Promise<SuggestionResponse[]>{
        try{
            const res=await $api.get<SuggestionResponse[]>(
                `/suggestions/lists/suggestions/${list}`,
                {
                    params:{
                        time:new Date().getTime(),
                    }
                }
            );
            return res.data;
        }catch (e){
            throw e
        }
    }

    static async createSuggestion({listId, name, files, description,message}: {
        listId: string,
        name: string,
        files: File[],
        description:string,
        message:string
    }): Promise<SuggestionResponse> {
        try{
            let formData = new FormData();
            formData.append('name',name)
            formData.append('description',name)
            formData.append('listId',listId)
            formData.append('message',message)
            files.forEach((f,index)=>{
                const file = new File([f], encodeURIComponent(f.name));

                formData.append(`files`,file)
            })



            const res=await $api.post<SuggestionResponse>(
                `/suggestions/suggestions/create`,
                formData,
                {
                    params:{
                        time:new Date().getTime(),
                    },
                    headers: {
                        'Content-Type': 'multipart/form-data; charset=UTF-8'
                    }
                }
            );
            return res.data;
        }catch (e){
            if(e instanceof AxiosError){
                console.error(`${JSON.stringify(e.response?.data)}`)
                console.error(e)
            }
            throw e
        }
    }
    static async deleteSuggestion(id:string):Promise<any> {
        try{
            const res=await $api.delete(
                `/suggestions/delete/${id}`,
            )
            return res.data;
        }catch (e){
            throw e
        }
    }

    static async fetchUsers():Promise<UserResponse[]> {
        try{
            const res=await $api.get(
                `/portal/users`,
            )
            return res.data;
        }catch (e){
            throw e
        }
    }
    static async updateUsers(userId:string,teamId:string):Promise<UserResponse> {
        try{
            const res=await $api.put(
                `/portal/users/${userId}`,
                {
                    teamId:teamId,
                }
            )
            return res.data;
        }catch (e){
            throw e
        }
    }
}

export const fetchSuggestion = async (portalId?:string):Promise<SuggestionListResponse[]> => {
    try {
        const res=await $api.get<SuggestionListResponse[]>(
            '/suggestions/lists',
            {
                params:{
                    time:new Date().getTime(),
                    portalId:portalId
                }
            }
        );
        return res.data;
    }catch (e) {
        throw new Error(`Error fetching data: ${e}`);
    }
}

export const openAllSuggestion = async (portalId?:string):Promise<ChatSuggestionResponse[]> => {
    try {
        const res=await $api.get<ChatSuggestionResponse[]>(
            '/suggestions/openChat',
            {
                params:{
                    time:new Date().getTime(),
                    portalId:portalId
                }
            }
        );
        return res.data;
    }catch (e) {
        throw new Error(`Error fetching data: ${e}`);
    }
}
export const fetchTeams = async (portalId?:string):Promise<TeamResponse[]> => {
    try {
        const res=await $api.get<TeamResponse[]>(
            '/portal/teams',
            {
                params:{
                    time:new Date().getTime(),
                    portalId:portalId
                }
            }
        );
        return res.data;
    }catch (e) {
        throw new Error(`Error fetching data: ${e}`);
    }
}