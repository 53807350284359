import React from 'react';

const PrivacyPage = () => {
    return (
        <div>
                <div>
                        <h1>Политика в отношении обработки персональных данных</h1>

                        <h2>1. Общие положения</h2>
                        <p>
                                Настоящая политика обработки персональных данных составлена в соответствии с требованиями Федерального закона от 27.07.2006. № 152-ФЗ «О персональных данных» (далее — Закон о персональных данных) и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных, предпринимаемые ИП Котельников Юрий Тимофеевич (далее — Оператор).
                        </p>
                        <p>
                                1.1. Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.
                        </p>
                        <p>
                                1.2. Настоящая политика Оператора в отношении обработки персональных данных (далее — Политика) применяется ко всей информации, которую Оператор может получить о посетителях веб-сайта <a href="https://chatboost.space/" target="_blank" rel="noopener noreferrer">https://chatboost.space/</a>.
                        </p>

                        <h2>2. Основные понятия, используемые в Политике</h2>
                        <p>2.1. Автоматизированная обработка персональных данных — обработка персональных данных с помощью средств вычислительной техники.</p>
                        <p>2.2. Блокирование персональных данных — временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных).</p>
                        <p>2.3. Веб-сайт — совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу <a href="https://chatboost.space/" target="_blank" rel="noopener noreferrer">https://chatboost.space/</a>.</p>
                        <p>2.4. Информационная система персональных данных — совокупность содержащихся в базах данных персональных данных и обеспечивающих их обработку информационных технологий и технических средств.</p>
                        <p>2.5. Обезличивание персональных данных — действия, в результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту персональных данных.</p>
                        <p>2.6. Обработка персональных данных — любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.</p>
                        <p>2.7. Оператор — государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и/или осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.</p>
                        <p>2.8. Персональные данные — любая информация, относящаяся прямо или косвенно к определенному или определяемому Пользователю веб-сайта <a href="https://chatboost.space/" target="_blank" rel="noopener noreferrer">https://chatboost.space/</a>.</p>
                        <p>2.9. Персональные данные, разрешенные субъектом персональных данных для распространения, — персональные данные, доступ неограниченного круга лиц к которым предоставлен субъектом персональных данных путем дачи согласия на обработку персональных данных, разрешенных субъектом персональных данных для распространения в порядке, предусмотренном Законом о персональных данных (далее — персональные данные, разрешенные для распространения).</p>
                        <p>2.10. Пользователь — любой посетитель веб-сайта <a href="https://chatboost.space/" target="_blank" rel="noopener noreferrer">https://chatboost.space/</a>.</p>
                        <p>2.11. Предоставление персональных данных — действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц.</p>
                        <p>2.12. Распространение персональных данных — любые действия, направленные на раскрытие персональных данных неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой информации, размещение в информационно-телекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным способом.</p>
                        <p>2.13. Трансграничная передача персональных данных — передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому или иностранному юридическому лицу.</p>
                        <p>2.14. Уничтожение персональных данных — любые действия, в результате которых персональные данные уничтожаются безвозвратно с невозможностью дальнейшего восстановления содержания персональных данных в информационной системе персональных данных и/или уничтожаются материальные носители персональных данных.</p>

                        <h2>3. Основные права и обязанности Оператора</h2>
                        <p>3.1. Оператор имеет право:</p>
                        <ul>
                                <li>получать от субъекта персональных данных достоверные информацию и/или документы, содержащие персональные данные;</li>
                                <li>в случае отзыва субъектом персональных данных согласия на обработку персональных данных, а также, направления обращения с требованием о прекращении обработки персональных данных, Оператор вправе продолжить обработку персональных данных без согласия субъекта персональных данных при наличии оснований, указанных в Законе о персональных данных;</li>
                                <li>самостоятельно определять состав и перечень мер, необходимых и достаточных для обеспечения выполнения обязанностей, предусмотренных Законом о персональных данных и принятыми в соответствии с ним нормативными правовыми актами, если иное не предусмотрено Законом о персональных данных или другими федеральными законами.</li>
                        </ul>

                        <p>3.2. Оператор обязан:</p>
                        <ul>
                                <li>предоставлять субъекту персональных данных по его просьбе информацию, касающуюся обработки его персональных данных;</li>
                                <li>организовывать обработку персональных данных в порядке, установленном действующим законодательством РФ;</li>
                                <li>отвечать на обращения и запросы субъектов персональных данных и их законных представителей в соответствии с требованиями Закона о персональных данных;</li>
                                <li>сообщать в уполномоченный орган по защите прав субъектов персональных данных по запросу этого органа необходимую информацию в течение 10 дней с даты получения такого запроса;</li>
                                <li>публиковать или иным образом обеспечивать неограниченный доступ к настоящей Политике в отношении обработки персональных данных;</li>
                                <li>принимать правовые, организационные и технические меры для защиты персональных данных от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а также от иных неправомерных действий в отношении персональных данных;</li>
                                <li>прекратить передачу (распространение, предоставление, доступ) персональных данных, прекратить обработку и уничтожить персональные данные в порядке и случаях, предусмотренных Законом о персональных данных;</li>
                                <li>исполнять иные обязанности, предусмотренные Законом о персональных данных.</li>
                        </ul>

                        <h2>4. Основные права и обязанности субъектов персональных данных</h2>
                        <p>4.1. Субъекты персональных данных имеют право:</p>
                        <ul>
                                <li>получать информацию, касающуюся обработки его персональных данных, за исключением случаев, предусмотренных федеральными законами. Сведения предоставляются субъекту персональных данных Оператором в доступной форме, и в них не должны содержаться персональные данные, относящиеся к другим субъектам персональных данных, за исключением случаев, когда имеются законные основания для раскрытия таких персональных данных. Перечень информации и порядок ее получения установлен Законом о персональных данных;</li>
                                <li>требовать от оператора уточнения его персональных данных, их блокирования или уничтожения в случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки;</li>
                                <li>отзывать согласие на обработку персональных данных;</li>
                                <li>обращаться с жалобами на нарушения законодательства Российской Федерации в области обработки персональных данных в уполномоченный орган по защите прав субъектов персональных данных либо в суд;</li>
                                <li>пользоваться иными правами, предусмотренными законодательством Российской Федерации.</li>
                        </ul>

                        <h2>5. Принципы и условия обработки персональных данных</h2>
                        <p>5.1. Обработка персональных данных осуществляется на законной и справедливой основе.</p>
                        <p>5.2. Обработка персональных данных ограничивается достижением конкретных, заранее определенных и законных целей. Не допускается обработка персональных данных, несовместимая с целями сбора персональных данных.</p>
                        <p>5.3. Обработка персональных данных осуществляется с согласия субъекта персональных данных на обработку его персональных данных для одной или нескольких целей, за исключением случаев, предусмотренных Законом о персональных данных.</p>
                        <p>5.4. Не допускается объединение баз данных, содержащих персональные данные, обработка которых осуществляется для несовместимых целей.</p>
                        <p>5.5. Обработка персональных данных осуществляется в пределах достижимых целей и по их окончании должны быть уничтожены или обезличены.</p>
                        <p>5.6. Обработка персональных данных должна быть осуществлена в форме, которая позволяет установить личность субъекта персональных данных, если это не требуется для достижения целей обработки персональных данных.</p>
                        <p>5.7. Лица, которые имеют доступ к персональным данным, обязаны соблюдать конфиденциальность персональных данных, за исключением случаев, когда законодательством Российской Федерации предусмотрено иное.</p>

                        <h2>6. Условия обработки персональных данных</h2>
                        <p>6.1. Обработка персональных данных допускается только при наличии как минимум одного из следующих условий:</p>
                        <ul>
                                <li>согласие субъекта персональных данных на обработку его персональных данных;</li>
                                <li>необходимость обработки персональных данных для достижения целей, предусмотренных международным договором Российской Федерации, для выполнения функций, полномочий и обязанностей, возложенных на Оператора законодательством Российской Федерации;</li>
                                <li>необходимость обработки персональных данных для исполнения договора, стороной которого является субъект персональных данных;</li>
                                <li>необходимость защиты жизненно важных интересов субъекта персональных данных;</li>
                                <li>необходимость обработки персональных данных для осуществления прав и законных интересов Оператора или третьих лиц.</li>
                        </ul>

                        <h2>7. Перечень действий (операций), совершаемых с персональными данными</h2>
                        <p>7.1. Оператор осуществляет следующие действия (операции) с персональными данными:</p>
                        <ul>
                                <li>сбор;</li>
                                <li>запись;</li>
                                <li>систематизация;</li>
                                <li>накопление;</li>
                                <li>хранение;</li>
                                <li>уточнение;</li>
                                <li>извлечение;</li>
                                <li>использование;</li>
                                <li>передача;</li>
                                <li>обезличивание;</li>
                                <li>блокирование;</li>
                                <li>удаление;</li>
                                <li>уничтожение.</li>
                        </ul>

                        <h2>8. Меры по обеспечению безопасности персональных данных</h2>
                        <p>8.1. Оператор принимает правовые, организационные и технические меры для обеспечения безопасности персональных данных при их обработке.</p>
                        <p>8.2. Оператор обеспечивает конфиденциальность персональных данных и защищает их от неправомерного доступа, утраты, уничтожения или изменения.</p>
                        <p>8.3. Оператор применяет современные методы защиты данных и технологические средства для защиты персональных данных.</p>

                        <h2>9. Заключительные положения</h2>
                        <p>9.1. Настоящая Политика может быть изменена или дополнена Оператором без предварительного согласия субъекта персональных данных.</p>
                        <p>9.2. Все изменения и дополнения к Политике будут размещены на веб-сайте <a href="https://chatboost.space/" target="_blank" rel="noopener noreferrer">https://chatboost.space/</a>.</p>
                        <p>9.3. Настоящая Политика вступает в силу с момента ее размещения на веб-сайте Оператора.</p>
                </div>
        </div>
    );
};

export default PrivacyPage;