import React from 'react';
import CardContent from "@mui/material/CardContent";
import {Box, Dialog, DialogContent, DialogTitle, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import {SubmitHandler, useForm} from "react-hook-form";
import Stack from "@mui/material/Stack";
import {OrderServices} from "../../api/services/OrderServices";
import {AxiosError} from "axios";
import {useNavigate} from "react-router-dom";

type Inputs = {
    email: string
}

const BuyCard = () => {
    const [open, setOpen] = React.useState(false);
    const [payLink,setPayLink]=React.useState<string|undefined>();
    const navigate = useNavigate()

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        control
    } = useForm<Inputs>({
        defaultValues:{
        }
    })
    const onSubmit: SubmitHandler<Inputs> =async (data) => {
        try{
            const res=await OrderServices.buy(data.email)
            setPayLink(res.paymentUrl)
            setOpen(true)
        } catch (e){
            if(e instanceof AxiosError){
                alert(`${e.response?.data.message}`)
            }
            alert(e)
        }
    }
    const handleIframeLoad = (event: React.SyntheticEvent<HTMLIFrameElement>) => {
        const iframe = event.currentTarget;
        try {
            const url = iframe.contentWindow?.location.href;
            if (url) {
                if (url.includes('https://chatboost.space/susses/')) {
                    setOpen(false)
                    navigate(0)
                }
                if (url.includes('https://chatboost.space/fail/')) {
                    setOpen(false)
                    navigate(0)
                }
            }
        } catch (error) {
            console.error('Cannot access iframe URL due to cross-origin restrictions');
        }
    };
    return (
        <>
            <Card sx={{ mx: 'auto', mt: 4, boxShadow: 3, borderRadius: 2 }}>
                <CardContent>
                    <Typography variant="h5" component="div" gutterBottom>
                        Подписка на ChatBoost
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                        Получите полный доступ к функционалу ChatBoost всего за <strong>499 рублей</strong> в месяц.
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 2 }}>
                        Включает создание неограниченного числа быстрых ответов, управление командами, доступ к персонализированным спискам подсказок.
                    </Typography>
                    <Box textAlign="center" component={"form"} onSubmit={handleSubmit(onSubmit)}>
                        <Stack>
                            <TextField
                                autoFocus
                                required
                                margin="dense"
                                label="Email для чека"
                                variant="outlined"
                                {...register('email',{required:{value:true,message:'Заполните поле'}})}
                            />
                            <Button variant="contained" type={'submit'} color="primary" sx={{ borderRadius: 3 }}>
                                Оформить подписку за 499₽ в месяц
                            </Button>
                        </Stack>
                    </Box>
                </CardContent>
            </Card>


            <Dialog open={open} onClose={()=>setOpen(false)} maxWidth="lg" fullWidth>
                <DialogTitle>Платежная форма</DialogTitle>
                <DialogContent>
                    <iframe
                        src={payLink}
                        width="100%"
                        height="600px"
                        frameBorder="0"
                        title="Payment Form"
                        onLoad={handleIframeLoad}
                    ></iframe>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default BuyCard;