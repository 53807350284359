import React, {FC} from 'react';
import {Box, Typography} from "@mui/material";
import Container from "@mui/material/Container";

interface PaymentResultPageAttrs{
    result:'fail'|'susses'
}

const PaymentResultPage:FC<PaymentResultPageAttrs> = ({result}) => {

    return (
        <Container maxWidth="md">
            <Box my={4}>
                <Typography variant="h4" gutterBottom align="center">
                    {
                        result=='fail'&&'Ошибка платежа'
                    }
                    {
                        result=='susses'&&' Оплата прошла успешно. Спасибо за покупку'
                    }
                </Typography>
            </Box>
        </Container>
    );
};

export default PaymentResultPage;