import React from 'react';
import { Container, Typography, Box, List, ListItem, ListItemText } from '@mui/material';

const PublicOfferPage: React.FC = () => {
    return (
        <Container maxWidth="md" sx={{ marginTop: '20px' }}>
            <Typography variant="h4" gutterBottom>
                Публичная оферта на оказание платных услуг по подписке
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                Дата публикации: 16 сентября 2024
            </Typography>

            <Box mb={4}>
                <Typography variant="body1" gutterBottom>
                    В целях настоящей Оферты нижеприведенные термины используются в следующем значении:
                </Typography>

                <List>
                    <ListItem>
                        <ListItemText primary="Исполнитель — индивидуальный предприниматель Котельников Юрий Тимофеевич." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Заказчик — дееспособное физическое или юридическое лицо, оформившее подписку на Сайте." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={'Сайт — веб-сайт https://chatboost.space, принадлежащий и администрируемый Исполнителем.'} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Подписка — предоставление Заказчику доступа к приложению ChatBoost в Битрикс24 Маркете на платной основе в течение оплаченного периода." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Приложение ChatBoost — программный продукт, доступный в Битрикс24 Маркете по ссылке: https://www.bitrix24.ru/apps/app/ekbapp_2.chatboost/." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Договор — договор возмездного оказания услуг по подписке, заключаемый между Исполнителем и Заказчиком на условиях настоящей Оферты." />
                    </ListItem>
                </List>
            </Box>

            <Box mb={4}>
                <Typography variant="h4" gutterBottom>
                    2. Предмет Оферты
                </Typography>

                <Box mb={4}>
                    <Typography variant="body1" gutterBottom>
                        Исполнитель обязуется предоставить Заказчику доступ к Приложению ChatBoost на условиях платной подписки,
                        а Заказчик обязуется оплачивать услуги в соответствии с условиями настоящей Оферты.
                    </Typography>
                </Box>
            </Box>

            <Box mb={4}>
                <Typography variant="h4" gutterBottom>
                    3. Условия оплаты и периодичность списания
                </Typography>

                <Box mb={4}>
                    <Typography variant="h6" gutterBottom>
                        3.1. Периодичность и размер списания
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        3.1.1. Стоимость подписки составляет 499 (четыреста девяносто девять) рублей за каждые 30 календарных дней.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        3.1.2. Списание денежных средств осуществляется автоматически каждые 30 календарных дней с момента оформления подписки с использованием платежных данных, предоставленных Заказчиком.
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                        3.2. Правила отмены и возврата
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        3.2.1. Заказчик имеет право в любое время отменить подписку.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        3.2.2. Для отмены подписки Заказчик должен указать свой домен Bitrix24 и контактную электронную почту на странице <a href={'https://chatboost.space/cancel-subscription'} target={'_blank'}>https://chatboost.space/cancel-subscription</a>. В течение 3 (трех) рабочих дней Заказчику будет отправлено письмо с результатом отмены подписки.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        3.2.3. При отмене подписки дальнейшие списания отменяются, и Заказчик может пользоваться подпиской до окончания оплаченного 30-дневного периода.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        3.2.4. Возврат денежных средств возможен только в случае, если услуги не были предоставлены по вине Исполнителя. Для оформления возврата Заказчик должен обратиться к Исполнителю по контактным данным, указанным в разделе 8.
                    </Typography>
                </Box>
            </Box>
            <Box mb={4}>
                <Typography variant="h6" gutterBottom>
                    4. Порядок заключения Договора
                </Typography>
                <Box mb={4}>
                    <Typography variant="body1" gutterBottom>
                        4.1. Договор между Исполнителем и Заказчиком заключается посредством акцепта настоящей Оферты.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        4.2. Акцептом Оферты считается совершение Заказчиком следующих действий:
                    </Typography>
                    <Box ml={4}>
                        <Typography variant="body1" gutterBottom>
                            - Регистрация или авторизация на Сайте.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            - Оформление подписки и предоставление необходимых платежных данных.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            - Оплата стоимости подписки.
                        </Typography>
                    </Box>
                    <Typography variant="body1" gutterBottom>
                        4.3. Моментом заключения Договора считается дата поступления оплаты на счет Исполнителя.
                    </Typography>
                </Box>
            </Box>

            <Box mb={4}>
                <Typography variant="h4" gutterBottom>
                    5. Права и обязанности сторон
                </Typography>

                <Box mb={4}>
                    <Typography variant="h6" gutterBottom>
                        5.1. Исполнитель обязуется:
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        5.1.1. Предоставить Заказчику доступ к Приложению ChatBoost после подтверждения оплаты.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        5.1.2. Обеспечивать работоспособность Приложения и своевременно устранять технические неполадки.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        5.1.3. Оказывать информационную поддержку Заказчику по вопросам использования Приложения.
                    </Typography>
                </Box>

                <Box mb={4}>
                    <Typography variant="h6" gutterBottom>
                        5.2. Исполнитель имеет право:
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        5.2.1. Приостанавливать доступ к услугам в случае нарушения Заказчиком условий настоящей Оферты или действующего законодательства Российской Федерации.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        5.2.2. Вносить изменения в условия Оферты с уведомлением Заказчика путем размещения новой редакции на Сайте.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        5.2.3. Отказать в предоставлении услуг в случае неполной или некорректной оплаты.
                    </Typography>
                </Box>

                <Box mb={4}>
                    <Typography variant="h6" gutterBottom>
                        5.3. Заказчик обязуется:
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        5.3.1. Предоставлять достоверные и актуальные данные при оформлении подписки и использовании услуг.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        5.3.2. Своевременно и в полном объеме оплачивать услуги в соответствии с условиями настоящей Оферты.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        5.3.3. Не передавать свои учетные данные третьим лицам и обеспечивать их конфиденциальность.
                    </Typography>
                </Box>

                <Box mb={4}>
                    <Typography variant="h6" gutterBottom>
                        5.4. Заказчик имеет право:
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        5.4.1. Пользоваться Приложением ChatBoost в течение оплаченного периода.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        5.4.2. Отменить подписку в любое время в соответствии с правилами отмены, установленными в разделе 3.2.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        5.4.3. Обращаться к Исполнителю с вопросами и претензиями по качеству предоставляемых услуг.
                    </Typography>
                </Box>
            </Box>

            <Box mb={4}>
                <Typography variant="h4" gutterBottom>
                    6. Ответственность сторон
                </Typography>

                <Box mb={4}>
                    <Typography variant="body1" gutterBottom>
                        6.1. За неисполнение или ненадлежащее исполнение обязательств по настоящей Оферте стороны несут ответственность в соответствии с законодательством Российской Федерации.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        6.2. Исполнитель не несет ответственности за непредоставление или ненадлежащее предоставление услуг в случае, если это вызвано действиями или бездействием третьих лиц и/или сбоем в работе программного обеспечения или оборудования, не принадлежащего Исполнителю.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        6.3. Заказчик несет ответственность за достоверность предоставляемых данных и сохранность своих учетных данных.
                    </Typography>
                </Box>
            </Box>

            <Box mb={4}>
                <Typography variant="h4" gutterBottom>
                    7. Порядок разрешения споров
                </Typography>

                <Box mb={4}>
                    <Typography variant="body1" gutterBottom>
                        7.1. Все споры и разногласия, возникающие из настоящей Оферты или в связи с ней, подлежат разрешению путем переговоров между сторонами.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        7.2. В случае недостижения соглашения споры подлежат рассмотрению в судебном порядке по месту нахождения Исполнителя.
                    </Typography>
                </Box>
            </Box>

            <Box mb={4}>
                <Typography variant="h6" gutterBottom>
                    8. Контактные данные
                </Typography>
                <Typography variant="body1">
                    Email: info@chatboost.space
                </Typography>
            </Box>


            <Typography variant="h4" gutterBottom>
                9. Реквизиты Исполнителя
            </Typography>
            <Box mb={4}>
                <Typography variant="body1" gutterBottom>
                    <strong>Индивидуальный предприниматель:</strong> Котельников Юрий Тимофеевич
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>ИНН:</strong>667354456790</Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>ОГРНИП:</strong>319665800165693</Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Расчетный счет:</strong> 40802810700001202317
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Банк:</strong> АО «ТБанк»
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>ИНН банка:</strong> 7710140679
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>БИК банка:</strong> 044525974
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Корреспондентский счет банка:</strong> 30101810145250000974
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Юридический адрес банка:</strong> 127287, г. Москва, ул. 2-я Хуторская, д. 38А, стр. 26
                </Typography>
            </Box>

            <Typography variant="h4" gutterBottom>
                10. Заключительные положения
            </Typography>
            <Box mb={4}>
                <Typography variant="body1" gutterBottom>
                    10.1. Настоящая Оферта вступает в силу с момента ее размещения на Сайте и действует до момента ее отзыва Исполнителем.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    10.2. Исполнитель имеет право в одностороннем порядке вносить изменения в условия настоящей Оферты. Изменения вступают в силу с момента их размещения на Сайте.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    10.3. Продолжение использования услуг после внесения изменений в Оферту считается согласием Заказчика с такими изменениями.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    10.4. Во всем, что не предусмотрено настоящей Офертой, Стороны руководствуются действующим законодательством Российской Федерации.
                </Typography>
            </Box>
        </Container>
    );
};

export default PublicOfferPage;
