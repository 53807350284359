import React, {FC, useState} from 'react';
import {Box, List, ListItem, ListItemButton, ListItemText, Tab, Tabs} from "@mui/material";
import {ChatSuggestionResponse, SuggestionItem} from "../../types/api/ChatSuggestionResponse";
import {useNavigate, useSearchParams} from "react-router-dom";
import TextField from "@mui/material/TextField";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{p: 3}}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


interface SuggestionTabsProps {
    items: ChatSuggestionResponse[]
}

const SuggestionTabs: FC<SuggestionTabsProps> = ({items}) => {
    const [value, setValue] = React.useState(0);
    const allSuggestions = items.flatMap(item => item.suggestions);
    const [searchText, setSearchText] = useState<string>("");

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();


    const onTap = (item: SuggestionItem) => {
        navigate(`/send/${item.id}?portalId=${searchParams.get('portalId')}&dialog=${searchParams.get('DIALOG_ID')}`)
    }

    const filterSuggestions = (suggestions: SuggestionItem[]) => {
        return suggestions.filter(suggestion =>
            suggestion.name.toLowerCase().includes(searchText.toLowerCase()) ||
            suggestion.message.toLowerCase().includes(searchText.toLowerCase())
        );
    };


    return (
        <div>
            <Box sx={{width: '100%'}}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Все подсказки" {...a11yProps(0)} />
                        {items.map((item, index) => (
                            <Tab key={item.id} label={item.name} {...a11yProps(index + 1)} />
                        ))}
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    <TextField
                        fullWidth
                        label="Поиск подсказок"
                        variant="outlined"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)} // Обновляем строку поиска
                        sx={{ mb: 2 }} // Добавляем отступ
                    />
                    {filterSuggestions(allSuggestions).length ? (
                        <List disablePadding>
                            {
                                filterSuggestions(allSuggestions).map(suggestion => (
                                    <ListItemButton
                                        key={`all-list-${suggestion.id}`}
                                        onClick={() => onTap(suggestion)}
                                    >
                                        <ListItemText
                                            primary={suggestion.name}
                                            secondary={suggestion.message}
                                            secondaryTypographyProps={{
                                                sx: {
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    width: '200px',
                                                    WebkitBoxOrient: 'vertical',
                                                    WebkitLineClamp: 1,
                                                }
                                            }}
                                        />
                                    </ListItemButton>
                                ))
                            }
                        </List>
                    ) : (
                        <p>Нет подсказок</p>
                    )}
                </CustomTabPanel>
                {items.map((item, index) => (
                    <CustomTabPanel key={item.id} value={value} index={index + 1}>
                        <TextField
                            fullWidth
                            label="Поиск в этом списке"
                            variant="outlined"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            sx={{ mb: 2 }}
                        />
                        {filterSuggestions(item.suggestions).length ? (
                            <List disablePadding>
                                {
                                    filterSuggestions(item.suggestions).map((suggestion, index) => (
                                        <ListItemButton
                                            onClick={() => onTap(suggestion)}
                                            key={index}>
                                            <ListItemText
                                                primary={suggestion.name}
                                                secondary={suggestion.message}
                                            />
                                        </ListItemButton>
                                    ))
                                }
                            </List>
                        ) : (
                            <p>Нет подсказок в этом списке</p>
                        )}
                    </CustomTabPanel>
                ))}
            </Box>
        </div>
    );
};

export default SuggestionTabs;