import React, {FC} from 'react';
import IconButton from "@mui/material/IconButton";
import {
    Checkbox,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    List,
    ListItem, ListItemButton, ListItemIcon, ListItemText,
    Tooltip,
    Typography
} from "@mui/material";
import {Edit} from "@mui/icons-material";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import $api from "../../../api/api";
import {AxiosError} from "axios";
import {useListStorage} from "../../../storage/ListStorage";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {useTeamsStorage} from "../../../storage/TeamStore";

type Inputs = {
    listId:string
    name: string
    teamIds:string[]
}

interface EditListAttrs{
    listId:string
    teamIds:string[]
    name:string
}

const EditListButton:FC<EditListAttrs> = ({name,teamIds,listId}) => {
    const [open, setOpen] = React.useState(false);
    const {unsetList}=useListStorage()
    const {teams}=useTeamsStorage()


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        control
    } = useForm<Inputs>({
        defaultValues:{
            teamIds:teamIds,
            name:name,
            listId:listId
        }
    })

    const onSubmit: SubmitHandler<Inputs> =async (data) => {
        try{
            const res=await $api.put(
                `/suggestions/list`,
                data
            )
            unsetList(listId,res.data )
            handleClose()
        } catch (e){
            if(e instanceof AxiosError){
                alert(`${e.response?.data.message}`)
            }else{
                alert(e)
            }
        }
    }


    return (
        <>
            <Tooltip title={'Редактировать список'}>
                <IconButton onClick={handleClickOpen}>
                    <Edit/>
                </IconButton>
            </Tooltip>

            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: handleSubmit(onSubmit),
                }}
            >

                <DialogTitle>Редактировать список</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Измените данные для списка {name}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        label="Название списка"
                        fullWidth
                        variant="outlined"
                        {...register('name',{required:{value:true,message:'Заполните поле'}})}
                    />
                    <Typography>Выберите команды для этого списка:</Typography>
                    <Controller
                        render={({field:{value,onChange}})=>(
                            <List>
                                {
                                    teams.map((team,index)=>{
                                        const labelId = `checkbox-list-label-${team.id}`;

                                        const handleToggle = (id: string) => () => {
                                            const currentIndex = value.indexOf(id);
                                            const newChecked = [...value];

                                            if (currentIndex === -1) {
                                                newChecked.push(id);
                                            } else {
                                                newChecked.splice(currentIndex, 1);
                                            }

                                            onChange(newChecked);
                                        };

                                        return (
                                            <ListItem
                                                key={team.id}
                                                disablePadding
                                            >
                                                <ListItemButton role={undefined} onClick={handleToggle(team.id)} dense>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            edge="start"
                                                            checked={value.indexOf(team.id) !== -1}
                                                            tabIndex={-1}
                                                            disableRipple
                                                            inputProps={{ 'aria-labelledby': labelId }}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText id={labelId} primary={team.name} />
                                                </ListItemButton>
                                            </ListItem>
                                        )
                                    })
                                }
                            </List>
                        )}
                        name={'teamIds'}
                        control={control}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Отмена</Button>
                    <Button type="submit">Сохранить</Button>
                </DialogActions>

            </Dialog>
        </>
    );
};

export default EditListButton;