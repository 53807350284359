import React, {useEffect, useState} from 'react';
import {useParams, useSearchParams} from "react-router-dom";
import {Box, CircularProgress, Tab, Tabs} from "@mui/material";
import {openAllSuggestion} from "../../api/services/AppServices";
import {ChatSuggestionResponse} from "../../types/api/ChatSuggestionResponse";
import SuggestionTabs from "../../components/Chat/SuggestionTabs";
import {AxiosError} from "axios";
import {format} from "date-fns";
import {ru} from "date-fns/locale";


const ChatPage = () => {
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string|null>(null);
    const { portalId } = useParams();
    const [items,setItems]=useState<ChatSuggestionResponse[]>([])

    useEffect(() => {
        if (searchParams.has('token')){
            if(searchParams.has('status')){
                switch (searchParams.get('status')){
                    case 'ok':
                        fetchLists().then();
                        break;
                    case 'need_pay':
                        let deadline=''
                        if(searchParams.has('deadlinePayment')){
                            try{
                                deadline=format(new Date(searchParams.get('deadlinePayment')!), 'dd MMMM yyyy', { locale: ru });
                            }catch (e){

                            }
                        }
                        setError(`Подписка на приложение закончилась ${deadline}. Вы можете продлить её в личном кабинете приложения.`)
                        setLoading(false)
                        break;
                }
            }
        }else {
            setError('токен не получен')
        }
    }, [searchParams]);

    async function fetchLists() {
        localStorage.setItem('authToken',searchParams.get('token')!)
        try {
            const res=await openAllSuggestion(portalId)
            setItems(res)
        }catch (e){
            if (e instanceof AxiosError){
                setError(`Ошибка загрузки данных ${e.response?.status} ${e.response?.data?.message}`);
                console.error('Ошибка:', e);
            }
            else{
                setError('Ошибка загрузки данных');
                console.error('Ошибка:', e);
            }
        }finally {
            setLoading(false);
        }
    }
    if (loading) {
        return <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
        >
            <CircularProgress />
        </Box>
    }

    if (error) {
        return <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
        >
            {error}
        </Box>
    }

    return (
        <SuggestionTabs items={items}/>
    );
};

export default ChatPage;