import {create} from "zustand";
import {createJSONStorage, persist} from "zustand/middleware";

interface PortalDataAttrs {
    deadlineSubscribe: Date|null;
    setDeadlineSubscribe: (value: Date) => void;
    portalId: string|null;
    setPortalId: (value: string) => void;
}

export const usePortalDataStorage = create(
    persist<PortalDataAttrs>(
        (set)=>({
            deadlineSubscribe: null,
            setDeadlineSubscribe: (value) => set({deadlineSubscribe: value}),
            portalId: null,
            setPortalId: (value: string) => set({portalId: value}),
        }),
        {
            name: 'portalData',
        }
    )
)