import React, {FC, PropsWithChildren} from 'react';
import {Box} from "@mui/material";
import SideMenu from "./SideMenu";
import {alpha} from "@mui/material/styles";
import AppNavbar from "./AppNavbar";

const MainMenu:FC<PropsWithChildren> = ({children}) => {
    return (
        <Box sx={{ display: 'flex' }}>
            <SideMenu />
            <AppNavbar />
            <Box
                component="main"
                sx={(theme) => ({
                    flexGrow: 1,
                    backgroundColor: alpha(theme.palette.background.default, 1),
                    overflow: 'auto',
                })}
            >
                {children}
            </Box>
        </Box>
    );
};

export default MainMenu;