import {OrderResponse} from "../../types/api/OrderResponse";
import $api from "../api";
import {PortalResponse} from "../../types/api/PortalResponse";
import {BuyResponse} from "../../types/api/BuyResponse";

export class OrderServices {
    static async getAllOrders(portalId?:string): Promise<OrderResponse[]> {
        try{
            const res=await $api.get<OrderResponse[]>(
                '/orders',
                {
                    params:{
                        time:new Date().getTime(),
                        portalId:portalId
                    }
                }
            );
            return res.data;
        }catch (error) {
            throw error
        }
    }

    static async viewPortal():Promise<PortalResponse>{
        try{
            const res=await $api.get<PortalResponse>(
                '/portal',
                {
                    params:{
                        time:new Date().getTime(),
                    }
                }
            );
            return res.data;
        }catch (error) {
            throw error
        }
    }
    static async buy(email:string):Promise<BuyResponse>{
        try{
            const res=await $api.post<BuyResponse>(
                '/orders/buy',
                {
                    email:email,
                },
                {
                    params:{
                        time:new Date().getTime(),
                    }
                }
            );
            return res.data;
        }catch (error) {
            throw error
        }
    }
}