import React from 'react';
import { Container, Typography, Box, List, ListItem, ListItemText } from '@mui/material';

const LicenseAgreement = () => {
    return (
        <Container maxWidth="md">
            <Box my={4}>
                <Typography variant="h4" gutterBottom align="center">
                    ЛИЦЕНЗИОННОЕ СОГЛАШЕНИЕ НА ИСПОЛЬЗОВАНИЕ ПРОГРАММЫ ДЛЯ ЭВМ
                </Typography>
                <Typography variant="h5" gutterBottom>
                    ChatBoost
                </Typography>
                <Typography gutterBottom>
                    Уважаемый Пользователь! Перед началом установки, копирования либо иного использования ПО внимательно
                    ознакомьтесь с условиями настоящего Соглашения...
                </Typography>

                <Typography variant="h6" gutterBottom>
                    Основные термины настоящего Соглашения:
                </Typography>
                <List>
                    <ListItem>
                        <ListItemText primary="ПО – программа для ЭВМ ChatBoost, исключительные имущественные права на которую принадлежат Лицензиару." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Демо-версия ПО – версия ПО ChatBoost, в которой установлено ограничение по сроку её использования." />
                    </ListItem>
                </List>

                <Typography variant="h6" gutterBottom>
                    1. Предмет Соглашения
                </Typography>
                <Typography gutterBottom>
                    1.1. Лицензиар предоставляет Пользователю право использования ПО на условиях простой неисключительной лицензии...
                </Typography>
                <Typography gutterBottom>
                    1.4. Лицензиар предоставляет Пользователю право использования ПО на территории следующих стран: Российская Федерация, Украина, Республика Беларусь...
                </Typography>

                <Typography variant="h6" gutterBottom>
                    2. Авторские права
                </Typography>
                <Typography gutterBottom>
                    2.1. ПО является результатом интеллектуальной деятельности и объектом авторских прав, защищённых законодательством Российской Федерации.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    3. Условия использования ПО и ограничения
                </Typography>
                <Typography gutterBottom>
                    3.1. Соглашение предоставляет право установки и использования одной копии ПО на одном портале Битрикс24.
                </Typography>
                <Typography gutterBottom>
                    3.3. Пользователь вправе изменять файлы ПО только в случаях, предусмотренных законодательством.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    4. Ответственность сторон
                </Typography>
                <Typography gutterBottom>
                    4.1. За нарушение условий Соглашения наступает ответственность, предусмотренная законодательством Российской Федерации.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    5. Ограниченная гарантия
                </Typography>
                <Typography gutterBottom>
                    5.1. Лицензиар предоставляет право на получение Технической поддержки по вопросам, связанным с функциональностью ПО.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    6. Действие, изменение и расторжение Соглашения
                </Typography>
                <Typography gutterBottom>
                    6.1. Соглашение заключается и толкуется в соответствии с законодательством Российской Федерации.
                </Typography>
                <Typography gutterBottom>
                    6.3. При расторжении Соглашения Пользователь обязан прекратить использование ПО и удалить все копии.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    7. Контактная информация Лицензиара
                </Typography>
                <Typography gutterBottom>
                    Название ПО: ChatBoost
                </Typography>
                <Typography gutterBottom>
                    Наименование правообладателя: ИП Котельников Юрий Тимофеевич ИНН 667354456790
                    <a href={'mailto:y@kotelnikoff.expert'}>y@kotelnikoff.expert</a>

                </Typography>
            </Box>
        </Container>
    );
};

export default LicenseAgreement;
