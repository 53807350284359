import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import {useNavigate} from "react-router-dom";
import {useAppStorage} from "../../storage/AppStorage";
import {Analytics, Group} from "@mui/icons-material";
import Diversity3Icon from '@mui/icons-material/Diversity3';
import EqualizerIcon from '@mui/icons-material/Equalizer';

//    {text: 'Аналитика', icon: <EqualizerIcon/>, path: '/analytics'},
const mainListItems = [
    {text: 'Списки подсказок', icon: <HomeRoundedIcon/>, path: '/main-app'},
    {text: 'Команды', icon: <Group/>, path: '/team'},
    {text: 'Пользователи', icon: <Diversity3Icon/>, path: '/users'},
    {text: 'Подписка', icon: <AssignmentRoundedIcon/>, path: '/orders'},
];

const secondaryListItems = [
    {text: 'Настройки', icon: <SettingsRoundedIcon/>, path: '/settings'},
];

export default function MenuContent() {
    const navigate = useNavigate();
    const {selectPage, setSelectedPage} = useAppStorage()

    return (
        <Stack sx={{flexGrow: 1, p: 1, justifyContent: 'space-between'}}>
            <List dense>
                {mainListItems.map((item, index) => (
                    <ListItem key={index} disablePadding sx={{display: 'block'}}>
                        <ListItemButton selected={index == selectPage} onClick={() => {
                            navigate(item.path);
                            setSelectedPage(index)
                        }}>
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.text}/>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>

            <List dense>
                {secondaryListItems.map((item, index) => (
                    <ListItem key={index} disablePadding sx={{display: 'block'}}>
                        <ListItemButton onClick={() => {
                            navigate(item.path)
                            setSelectedPage(index+10)
                        }}
                                        selected={(index+10) == selectPage}
                        >
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.text}/>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Stack>
    );
}