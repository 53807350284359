import axios, { AxiosError } from 'axios';

interface ErrorResponse {
    message: string;
}

function handleError(error: unknown) :string{
    if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError<ErrorResponse>;
        if (axiosError.response) {
            const statusCode = axiosError.response.status;
            if (statusCode >= 400 && statusCode < 500) {
                return axiosError.response.data?.message || 'Произошла ошибка. Попробуйте еще раз.';
            }
            if (statusCode >= 500) {
                return 'Ошибка на стороне сервера. Попробуйте позже.';
            }
        } else if (axiosError.request) {
            return 'Ошибка сети. Проверьте подключение к интернету.';
        } else {
            return `Ошибка: ${axiosError.message}`;
        }
    } else {
        return 'Произошла неизвестная ошибка.';
    }
    return 'Произошла неизвестная ошибка.';

}

export default handleError;
