import React from 'react';
import Container from "@mui/material/Container";
import {Typography} from "@mui/material";
import Button from "@mui/material/Button";

const OrderSussesResult = () => {
    const handleRefresh = () => {
        window.location.reload(); // Перезагружает страницу
    };
    return (
        <Container style={{ textAlign: 'center', marginTop: '50px' }}>
            <Typography variant="h4" gutterBottom>
                Оплата прошла успешно!
            </Typography>
            <Typography variant="body1" gutterBottom>
                Спасибо за вашу оплату. Чтобы обновить информацию, пожалуйста, обновите страницу.
            </Typography>
            <Button variant="contained" color="primary" onClick={handleRefresh} style={{ marginTop: '20px' }}>
                Обновить страницу
            </Button>
        </Container>
    );
};

export default OrderSussesResult;