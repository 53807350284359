import React, {useEffect, useState} from 'react';
import {Box, CircularProgress, Typography} from "@mui/material";
import {useAppStorage} from "../../storage/AppStorage";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {TeamService} from "../../api/services/TeamService";
import {AppServices} from "../../api/services/AppServices";
import {OrderServices} from "../../api/services/OrderServices";
import {usePortalDataStorage} from "../../storage/PortalDataStorage";
import {useListStorage} from "../../storage/ListStorage";
import {useTeamsStorage} from "../../storage/TeamStore";

const LoaderPage = () => {
    const {setToken,setPortalId,setTeams,setLists,setPortalData}=useAppStorage()
    const {setDeadlineSubscribe,setPortalId:setPI}=usePortalDataStorage()
    const {setLists:setListStorageLists}=useListStorage()
    const {setTeams:setWorkTeams}=useTeamsStorage()
    const {portalId} = useParams();
    const [searchParams] = useSearchParams();
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();


    useEffect(() => {
        fetchData().then()
    }, []);
    const fetchData = async () => {

        try{
            const token=searchParams.get('token')
            if(!token){
                throw new Error('Токен не получен')
            }
            if(!portalId){
                throw new Error('Портал не получен')
            }
            localStorage.setItem('authToken', token)
            localStorage.setItem('portalId', portalId??'')
            setToken(searchParams.get('token')??'')
            setPortalId(portalId??'')
            setPI(portalId)


            const teams=await TeamService.fetchTeams(portalId);
            setTeams(teams);
            setWorkTeams(teams)
            const lists=await AppServices.fetchListsSuggestion(portalId);
            setLists(lists);
            setListStorageLists(lists);
            const portalData=await OrderServices.viewPortal();
            setPortalData(portalData)
            setDeadlineSubscribe(new Date(portalData.deadlinePayment))
            navigate('/main-app')
        }catch (e){
            setError(`${e}`)
        }

    }
    if(error){
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Typography>Ошибка: ${error}</Typography>
            </Box>
        );
    }

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <CircularProgress/>
        </Box>
    );
};

export default LoaderPage;