import React from 'react';
import { Container, Typography, Box, List, ListItem, ListItemText } from '@mui/material';

const B24PrivacyPolicy = () => {
    return (
        <Container maxWidth="md">
            <Box my={4}>
                <Typography variant="h4" gutterBottom align="center">
                    Условия о конфиденциальности персональной информации
                </Typography>
                <Typography variant="h5" gutterBottom>
                    Компания «ИП Котельников Юрий Тимофеевич»
                </Typography>
                <Typography gutterBottom>
                    Компания уважает право каждого человека на конфиденциальность...
                </Typography>

                <Typography variant="h6" gutterBottom>
                    1. Персональная информация, собираемая и обрабатываемая Компанией
                </Typography>
                <List>
                    <ListItem>
                        <ListItemText primary="1.1. Информация, которую Пользователь самостоятельно предоставляет Компании при регистрации или использовании Продуктов Компании." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="1.2. Информация, получаемая автоматически при использовании Продуктов Компании, в том числе cookies." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="1.3. Пользовательские данные – любая информация, загруженная Пользователем в Продукты Компании." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="1.4. Иная информация, получаемая Компанией для целей, указанных в разделе 2." />
                    </ListItem>
                </List>

                <Typography variant="h6" gutterBottom>
                    2. Цели сбора и обработки персональной информации пользователей
                </Typography>
                <List>
                    <ListItem>
                        <ListItemText primary="2.1. Для идентификации Пользователя при использовании Продуктов Компании." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="2.2. Для персонализации учета посещений и регистрации действий Пользователя." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="2.3. Для взаимодействия с Пользователем в рамках договоров." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="2.4. Для проведения статистических и иных исследований на основе обезличенных данных." />
                    </ListItem>
                </List>

                <Typography variant="h6" gutterBottom>
                    3. Условия обработки и передачи персональной информации третьим лицам
                </Typography>
                <List>
                    <ListItem>
                        <ListItemText primary="3.1. Хранение персональной информации в соответствии с функциональными назначениями Продуктов Компании." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="3.2. Раскрытие персональной информации третьим лицам при согласии Пользователя, защите прав Компании или по требованию закона." />
                    </ListItem>
                </List>

                <Typography variant="h6" gutterBottom>
                    4. Ограничения ответственности
                </Typography>
                <Typography gutterBottom>
                    Компания не инициирует размещение персональной информации и не контролирует ее актуальность, но оставляет за собой право требовать подтверждения достоверности данных Пользователем...
                </Typography>

                <Typography variant="h6" gutterBottom>
                    5. Защита персональной информации
                </Typography>
                <List>
                    <ListItem>
                        <ListItemText primary="Использование RSA-шифрования в Продуктах Компании." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Предоставление двухэтапной аутентификации для доступа к учетной записи." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Осуществление защиты авторизованных сессий." />
                    </ListItem>
                </List>

                <Typography variant="h6" gutterBottom>
                    6. Заключительные положения
                </Typography>
                <Typography gutterBottom>
                    Условия о конфиденциальности могут быть изменены Компанией в одностороннем порядке путем размещения новой редакции на сайте Компании...
                </Typography>

                <Typography variant="h6" gutterBottom>
                    7. Контактная информация
                </Typography>
                <Typography gutterBottom>
                    Контактная информация
                    <a href={'mailto:y@kotelnikoff.expert'}>y@kotelnikoff.expert</a>
                </Typography>
            </Box>
        </Container>
    );
};

export default B24PrivacyPolicy;
