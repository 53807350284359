import React, {FC, ReactNode} from 'react';
import {AxiosError} from "axios";
import {Box, CircularProgress} from "@mui/material";

interface ReuseComponentBuilderProps<T> {
    loading: boolean;
    error: Error | null|unknown;
    data: T;
    children: (data: T) => ReactNode;
}


const ReuseComponentBuilder = <T,>({ loading, error, data, children }: ReuseComponentBuilderProps<T>) => {
    if (loading) {
        return <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="80vh"
        >
            <CircularProgress />
        </Box>;
    }

    if (error) {
        let errorText=`Ошибка загрузки данных ${error}`;
        if(error instanceof AxiosError) {
            errorText=`Ошибка: статус: ${error.response?.status} ${error.response?.data?.message}`;
        }
        return <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="80vh"
        >
            {errorText}
        </Box>

    }

    return (
        <>
            {children(data)}
        </>
    );
};

export default ReuseComponentBuilder;