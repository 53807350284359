import * as React from 'react';
import Stack from '@mui/material/Stack';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import NavbarBreadcrumbs from "./NavbarBreadcrumbs";
import {PropsWithChildren} from "react";


export default function Header({children}:PropsWithChildren) {
    return (
        <Stack
            direction="row"
            sx={{
                width: '100%',
                alignItems: { xs: 'flex-start', md: 'center' },
                justifyContent: 'space-between',
                maxWidth: { sm: '100%', md: '1700px' },
                pt: 1.5,
            }}
            spacing={2}
        >
            <NavbarBreadcrumbs />
            <Stack direction="row" sx={{ gap: 1 }}>
                {children}
            </Stack>
        </Stack>
    );
}