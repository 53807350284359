// ButtonWithState.tsx
import React, { useState, useEffect } from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';

type ButtonState = 'idle' | 'loading' | 'success' | 'error';

interface ButtonWithStateProps extends ButtonProps {
    controller: ReturnType<typeof useButtonController>;
}

export const ButtonWithState: React.FC<ButtonWithStateProps> = ({ controller, ...props }) => {
    const { state, setState } = controller;

    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (state === 'success' || state === 'error') {
            timer = setTimeout(() => setState('idle'), 2000);
        }
        return () => clearTimeout(timer);
    }, [state, setState]);

    const renderContent = () => {
        switch (state) {
            case 'loading':
                return <CircularProgress size={24} color="inherit" />;
            case 'success':
                return <CheckIcon />;
            case 'error':
                return <ErrorIcon />;
            default:
                return props.children;
        }
    };

    return (
        <Button
            {...props}
            disabled={state === 'loading'}
            style={{
                backgroundColor: state === 'success' ? 'green' : state === 'error' ? 'red' : '',
            }}
        >
            {renderContent()}
        </Button>
    );
};

// useButtonController.ts
export const useButtonController = () => {
    const [state, setState] = useState<ButtonState>('idle');

    const startLoading = () => setState('loading');

    const showSuccess = async (): Promise<void> => {
        setState('success');
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setState('idle');
    };

    const showError = async (): Promise<void> => {
        setState('error');
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setState('idle');
    };

    return { state, setState, startLoading, showSuccess, showError };
};
