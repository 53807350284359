import React from 'react';
import MainMenu from "../../components/Main/MainMenu";
import Header from "../../components/Main/Header";
import Stack from "@mui/material/Stack";
import {List, ListItem, ListItemText} from "@mui/material";
import {useAppStorage} from "../../storage/AppStorage";
import IconButton from "@mui/material/IconButton";
import {ContentCopy} from "@mui/icons-material";

const SettingsPage = () => {
    const {token, portalId} = useAppStorage()
    const settings = [
        {
            title: 'Токен авторизации',
            value: token,
            description: 'Нажмите для копирования'
        },
        {
            title: 'ID Компании',
            value: portalId,
            description: portalId
        },
    ]
    const handleCopy = (textToCopy: string) => {
        console.log(textToCopy);
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                alert('Скопированно');
            })
            .catch(err => {
                console.error('Ошибка: ', err);
            });
    };

    return (
        <MainMenu>
            <Stack
                spacing={2}
                sx={{
                    mx: 3,
                    pb: 10,
                    mt: {xs: 8, md: 0},
                }}
            >
                <Header>

                </Header>
                <List>
                    {
                        settings.map((item, index) => (
                            <ListItem
                                key={`settings-${item}`}
                                secondaryAction={
                                    <IconButton
                                        onClick={()=>handleCopy(item.value??'Error')}
                                        edge="end" aria-label="comments">
                                        <ContentCopy />
                                    </IconButton>
                            }
                            >
                                <ListItemText
                                    primary={item.title}
                                    secondary={item.description}
                                >
                                </ListItemText>
                            </ListItem>))
                    }
                </List>
            </Stack>
        </MainMenu>
    );
};

export default SettingsPage;