import React, {FC, useEffect, useState} from 'react';
import ReuseComponentBuilder from "../ReuseComponent";
import {OrderResponse} from "../../types/api/OrderResponse";
import {Box, List, ListItem, ListItemText, Typography} from "@mui/material";
import {OrderServices} from "../../api/services/OrderServices";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import BuyCard from "./BuyCard";

interface OrdersProps {
    portalId?:string;
}
const OrdersComponent:FC<OrdersProps> = ({portalId}) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null|unknown>(null);
    const [orders,setOrders] = useState<OrderResponse[]>([]);

    useEffect(() => {
        initData().then()
    }, []);

    async function initData(){
        if (!portalId){
            setError(Error('ID Портала не получен'))
            setLoading(false)
            return
        }
        setLoading(true)
        try{
            const res=await OrderServices.getAllOrders(portalId)
            setOrders(res)
        }catch (e){
            setError(e)
        }
        finally {
            setLoading(false)
        }
    }


    return (
        <div>
            <ReuseComponentBuilder<OrderResponse[]>
                loading={loading}
                error={error}
                data={orders}
            >
                {(data) => (

                    <>
                        <BuyCard/>
                        <Typography variant="h5" mt={3} component="div" gutterBottom>История заказов:</Typography>
                        <List>
                            {
                                data.map((item, index) => (
                                    <ListItem key={`orders-${item.id}-${index}`}>
                                        <ListItemText
                                            primary={`Покупка от ${format(new Date(item.updatedAt), 'dd MMMM yyyy', { locale: ru })}`}
                                            secondary={`Покупка доступа к приложению ChatBoost на 30 дней - ${item.status}`}
                                        >
                                        </ListItemText>
                                    </ListItem>
                                ))
                            }
                        </List>
                    </>
                )}
            </ReuseComponentBuilder>
        </div>
    );
};

export default OrdersComponent;