import React from 'react';
import {CssBaseline, Divider} from "@mui/material";
import AppAppBar from "../../components/site/AppAppBar";
import Hero from "../../components/site/Hero";
import LogoCollection from "../../components/site/LogoCollection";
import Features from "../../components/site/Features";
import Testimonials from "../../components/site/Testimonials";
import Highlights from "../../components/site/Highlights";
import Pricing from "../../components/site/Pricing";
import FAQ from "../../components/site/FAQ";
import Footer from "../../components/site/Footer";

const LandingPage = () => {
    return (
        <>
            <CssBaseline enableColorScheme />
            <AppAppBar />
            <Hero />
            <div>
                <Features />
                <Divider />
                <Pricing />
                <Divider />
                <Footer />
            </div>
        </>
    );
};

export default LandingPage;