
import {create} from "zustand";
import {SuggestionResponse} from "../types/api/SuggestionResponse";

interface SuggestionStoreAttrs{
    suggestions:SuggestionResponse[]
    setSuggestions:(value:SuggestionResponse[]) => void
    unsetSuggestion:(id:string,value:Partial<SuggestionResponse>) => void
    removeSuggestion:(id:string) => void
    addSuggestion:(value:SuggestionResponse)=>void

}
/// На странице листа подсказок
export const useSuggestionStorage = create<SuggestionStoreAttrs>((set,get) => ({
    suggestions:[],
    setSuggestions:(value:SuggestionResponse[]) => set({suggestions:value}),
    unsetSuggestion:(id:string,value:Partial<SuggestionResponse>) => set((state) => ({
        suggestions: state.suggestions.map((user) =>
            user.id === id ? { ...user, ...value } : user
        )
    })),
    removeSuggestion:(id:string) => set((state) => ({
        suggestions: state.suggestions.filter((user) => user.id !== id)
    })),
    addSuggestion:(value:SuggestionResponse)=>set((state) => ({
        suggestions:[value,...state.suggestions]
    }))
}))