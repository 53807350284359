import * as Sentry from "@sentry/react";


import React, {useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {YMInitializer} from "react-yandex-metrika";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

Sentry.init({
    dsn: "https://ecd22f18c672e0b170e65a2783ebc0fb@o4507916261785600.ingest.de.sentry.io/4507916848660560",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost:3000", /^https:\/\/api.chatboost\.space/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


root.render(
  <React.StrictMode>
      <YMInitializer accounts={[98204824]} />
      <App/>
  </React.StrictMode>
);