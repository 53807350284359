import {create} from "zustand";
import {TeamResponse} from "../types/api/TeamResponse";
import {SuggestionListResponse} from "../types/api/SuggestionListResponse";
import {PortalResponse} from "../types/api/PortalResponse";

interface AppStorageProps{
    token?:string
    portalId?:string
    setToken:(value:string) => void
    setPortalId:(value:string) => void
    teams:TeamResponse[]
    setTeams:(value:TeamResponse[]) => void
    lists:SuggestionListResponse[]
    setLists:(value:SuggestionListResponse[]) => void
    selectPage:number,
    setSelectedPage:(value:number) => void,
    portalData?:PortalResponse
    setPortalData:(value:PortalResponse) => void
}
export const useAppStorage = create<AppStorageProps>((set,getState)=>({
    setToken:(value:string) => set({token:value}),
    setPortalId:(value:string) => set({portalId:value}),
    teams:[],
    setTeams:(value:TeamResponse[]) => set({teams:value}),
    lists:[],
    setLists:(value:SuggestionListResponse[]) => set({lists:value}),
    selectPage:0,
    setSelectedPage:(value:number) => set({selectPage:value}),
    setPortalData:(value:PortalResponse) => set({portalData:value}),
}))