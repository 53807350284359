import React, {FC} from 'react';
import {useLoaderData, useLocation, useSearchParams} from "react-router-dom";

interface ErrorPageProps{
    message?:string
}

const ErrorPage:FC<ErrorPageProps> = (props) => {
    const [searchParams] = useSearchParams();
    const data = useLoaderData();
    const location = useLocation();
    const currentUrl = `${window.location.origin}${location.pathname}${location.search}`;

    return (
        <div>
            Упс...
            {props.message}<br/>
            {searchParams}<br/>
            {JSON.stringify(data)}<br/>
            {currentUrl}<br/>
        </div>
    );
};

export default ErrorPage;